import { elementExist } from './utils'

const STEPS = 'steps'
const CONTENT = 'content'
const NAVIGATION = 'navigation'
const PHRASES = 'phrases'

class Zapytanie {
  constructor(item) {
    this.el = {
      steps: item.querySelector('.form-steps'),
      content: item.querySelector('.form-content'),
      navigation: item.querySelector('.form-navigation'),
      phrases: item.querySelector('.form-phrases'),
    }
    this.form = this.getEl(CONTENT, 'form')
    this.step = 1
    this.lastStep = this.getEl(STEPS, '.step', true).length

    this.initNav()
  }


  validateNumbers(stepFields) {
    let numbers = [null, null, null]
    stepFields.forEach(({ name, value }) => {
      if(name === 'sztuki') numbers[0] = value
      else if(name === 'mb') numbers[1] = value
      else if(name === 'roboczogodziny') numbers[2] = value
    })
    let count = 0
    for(let i = 0; i < numbers.length; i++) {
      if(numbers[i] !== '') {
        count++
      }
    }
    return count === 1
  }

  nextStep() {
    let isValid = true
    const currentStep = this.getEl(CONTENT, `.tab[data-page="${this.step}"]`)
    const stepFields = currentStep.querySelectorAll('input, textarea, select')
    stepFields.forEach(field => {
      field.dispatchEvent(new Event('input', { bubbles: true }));
      field.dispatchEvent(new Event('change', { bubbles: true }));
      field.dispatchEvent(new Event('focusout'));
    })
    setTimeout(() => {
      stepFields.forEach(field => {
        if (field.classList.contains('wpcf7-not-valid')) {
            isValid = false;
        }
      })
      if(this.step === 4) {
        if(!this.validateNumbers(stepFields)) {
          isValid = false
          this.form.classList.remove('init')
          this.getEl(CONTENT, '.wpcf7-response-output').innerText = this.getEl(PHRASES, '#numbersError').innerText
        } else {
          this.form.classList.add('init')
        }
      } else {
        this.form.classList.add('init')
      }
      if(this.step < this.lastStep && isValid) this.setTab(this.step + 1)
    }, 0)
  }

  prevStep() {
    this.form.classList.add('init')
    if(this.step > 1) this.setTab(this.step - 1)
  }

  setTab(tab) {
    if(tab < 1 || tab > this.lastStep) return

    if(tab === 1) {
      this.getEl(NAVIGATION, '.prev').classList.remove('show')
    } else {
      this.getEl(NAVIGATION, '.prev').classList.add('show')
    }

    if(tab !== this.lastStep) {
      this.getEl(NAVIGATION, '.next').classList.add('show')
    } else {
      this.getEl(NAVIGATION, '.next').classList.remove('show')
    }

    this.getEl(CONTENT, '.tab', true).forEach(element => {
      element.classList.remove('current')
    })
    this.getEl(CONTENT, `.tab[data-page="${tab}"]`).classList.add('current')

    this.getEl(STEPS, '.step', true).forEach(element => {
      const page = Number(element.dataset.page)

      if(page < tab) {
        element.classList.add('complete')
      } else {
        element.classList.remove('complete')
      }

      if(page === tab) {
        element.classList.add('current')
      } else {
        element.classList.remove('current')
      }
    })


    this.step = tab
  }

  getEl(el, query, all = false) {
    if(all)
      return this.el[el].querySelectorAll(query)
    return this.el[el].querySelector(query)
  }


  initNav() {
    this.getEl(NAVIGATION, '.prev').addEventListener('click', this.prevStep.bind(this))
    this.getEl(NAVIGATION, '.next').addEventListener('click', this.nextStep.bind(this))

    const observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach(mutation => {
        if (mutation.attributeName === 'data-status') {
          if (this.form.dataset.status === 'sent' || this.form.dataset.status === 'unaccepted' || this.form.dataset.status === 'invalid') {
            this.form.classList.remove('init')
          }
          if (this.form.dataset.status === 'sent') {
            this.setTab(1)
          }
        }
      })
    })

    observer.observe(this.form, { attributes: true });
  }
}

elementExist('.zapytanie .form-container', (query, item) => {
  window.zapytanie = new Zapytanie(item[0])
})

elementExist('input[name="nowdate"]', (query, item) => {
  const now = new Date()
  item[0].value = now.toISOString().substring(0,10)
})