import './nav'
import './zapytanie'
import './zapytanie'
import { elementExist } from './utils'

elementExist('.home-slider', (query) => {
  const homeSliderInterval = 3500
  const homeSlider = new Splide ( query, {
    type: 'fade',
    arrows: false,
    autoplay: 'play',
    interval: homeSliderInterval,
  } )
  var homeSliderTimeout = null
  homeSlider.on( 'move', () => {
    clearTimeout(homeSliderTimeout)
    const last = homeSlider.Components.Controller.getEnd();
    const current = homeSlider.index
    if(current === last) {
      homeSliderTimeout = setTimeout(() => {
        homeSlider.Components.Controller.go(0, false)
      }, homeSliderInterval)
    }
  })
  homeSlider.mount()
})

elementExist('.news-slider', (query) => {
  const homeSlider = new Splide ( query, {
    perPage: 6,
    type: 'loop',
    arrows: false,
    fixedWidth: 300,
    gap: 24,
    pagination: true,
    breakpoints: {
      1945: {
        perPage: 5,
      },
      1615: {
        perPage: 4,
      },
      1295: {
        perPage: 3,
      },
      970: {
        perPage: 2
      },
      640: {
        perPage: 1,
        focus  : 'center',
      }
    }

  } )
  homeSlider.mount()
})

Fancybox.bind()
AOS.init()